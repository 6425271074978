import Swiper from 'swiper';
import { Navigation, Thumbs, EffectFade } from 'swiper/modules';

function gallerySlide12() {
  var swiper = new Swiper('.sprinkleSwiper2', {
    slidesPerGroup: 5,
    slidesPerView: 5,
    freeMode: true,
    allowTouchMove: false,
    watchSlidesProgress: true,
    loop: true,
    grid: {
      rows: 2,
      fill: 'row',
    },
  });
  var swiper2 = new Swiper('.sprinkleSwiper1', {
    modules: [Navigation, Thumbs, EffectFade],
    loop: true,
    effect: 'fade',
    thumbs: {
      swiper: swiper,
    },
    fadeEffect: {
      crossFade: true,
    },
  });
}

function gallerySlide34() {
  var swiper = new Swiper('.sprinkleSwiper4', {
    slidesPerGroup: 5,
    slidesPerView: 5,
    freeMode: true,
    allowTouchMove: false,
    watchSlidesProgress: true,
    loop: true,
    grid: {
      rows: 2,
      fill: 'row',
    },
  });
  var swiper2 = new Swiper('.sprinkleSwiper3', {
    modules: [Navigation, Thumbs, EffectFade],
    loop: true,
    effect: 'fade',
    thumbs: {
      swiper: swiper,
    },
    fadeEffect: {
      crossFade: true,
    },
  });
}

function gallerySlide56() {
  var swiper = new Swiper('.sprinkleSwiper6', {
    slidesPerGroup: 5,
    slidesPerView: 5,
    freeMode: true,
    allowTouchMove: false,
    watchSlidesProgress: true,
    loop: true,
    grid: {
      rows: 2,
      fill: 'row',
    },
  });
  var swiper2 = new Swiper('.sprinkleSwiper5', {
    modules: [Navigation, Thumbs, EffectFade],
    loop: true,
    effect: 'fade',
    thumbs: {
      swiper: swiper,
    },
    fadeEffect: {
      crossFade: true,
    },
  });
}

gallerySlide12();
gallerySlide34();
gallerySlide56();
